<template>
    <v-row class="fill-height">
        <v-container class="fill-height justify-center my-auto" fluid>
                <v-card flat width="400">
                    <v-card-title class="justify-center">
                       Create Super Admin Account
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-form>
                            <v-text-field label="Full Name"></v-text-field>
                            <v-text-field label="Email ID"></v-text-field>
                            <v-text-field label="Password"></v-text-field>
                            <v-text-field label="Re-enter Password"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn width="150" color="primary"> Add Account </v-btn>
                    </v-card-actions>
                </v-card>
        </v-container>
    </v-row>
</template>
<script>
export default {
    name: 'createSuperAdmin'
}
</script>
